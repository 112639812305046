.Links_Container {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 40px;
  background-color: var(--theme-default-color-secondary);
  padding: 10px 30px;
}

.Container_SingleSection {
  height: 100%;
  display: block;
  padding: 0 10px;
  transition: 0.5s ease-in-out;
  border-radius: 10px;
  box-shadow:  5px 5px 9px #cc8797,
  -5px -5px 9px #ffafc3;

  &:hover {
    transform: scale(1.1);
  }

  p {
    font-weight: bold;
  }
}

.SingleSection_LinkTitle {
  padding: 5px 0 10px 0;
  font-size: var(--font-size-extra-large-em);
  font-weight: bold;
  color: white;
}

.SingleSection_LinkSubtitle {
  color: white;
  font-size: var(--font-size-normal-em);
  padding: 5px 0 10px 0;
}


@media (max-width: 1000px) {
  .Links_Container {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .Links_Container a:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
  }

  .Links_Container a:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
  }

  .Links_Container a:nth-child(3) {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
  }
}

@media (max-width: 850px) {
  .Links_Container {
    display: block;
  }

  .Container_SingleSection {
    width: 80%;
    margin: 0 auto 25px auto;
  }
}
