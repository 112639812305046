.Head_Container {
  background-color: var(--theme-default-color-secondary);
  color: white;
  padding: 20px 16px 50px 16px;

  h2, p {
    color: white;
  }
}

.Head_Header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-extra-huge-rem);
  margin-bottom: 10px;
}


@media (max-width: 850px) {
  .Head_Header {
    font-size: var(--font-size-huge-rem);
  }
}
