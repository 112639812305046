.InputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0 20px 0;
  position: relative;
  font-size: var(--font-size-normal-rem);
}

.Label {
  color: #3e5474;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 10px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin-left: 1px;
  margin-bottom: 2px;
  text-align: left;
}

.LabelPinky {
  span {
    color: var(--color-image-pink);
  }

  div {
    background: var(--color-image-pink) !important;
  }
}

.LabelBlue {

  span {
    color: var(--color-image-blue);
  }

  div {
    background: var(--color-image-blue) !important;
  }
}

.LabelGreeny {
  span {
    color: var(--color-image-green);
  }

  div {
    background: var(--color-image-green) !important;
  }
}

