.SectionContainer {
  margin-top: 30px;
  padding: 20px 16px;
  box-shadow: var(--shadow-around-light);
  background-color: white;
  cursor: pointer;
}

.MainRow {
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: space-between;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px 0;
}

.ButtonContainer {
  margin-top: 20px;
  width: 100%;
}
