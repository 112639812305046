.CalculatorContainer {
  display: flex;
  justify-content: space-evenly;
  margin: 40px 0;
  padding: 20px 0;
  align-items: center;
  box-shadow: var(--card-box-shadow);
}

.Content_Inputs {
  min-width: 600px;
  padding: 20px 30px;
  box-shadow: 0 2px 6px -1px rgba(0,0,0,0.75);
}

.Content_Image {
  text-align: center;
  width: 100%;

  img {
    width: clamp(100px, 70%, 600px);
  }
}

.Content_ImageSmaller {
  text-align: center;
  width: 100%;
  img {
    width: clamp(100px, 50%, 600px);
  }
}


.ResultContainer {
  margin-top: 20px;
  font-size: var(--font-size-large-rem);
}

.ParagraphDescription {
  padding: 10px 20px;
  text-align: justify;
}

.HeaderContainer {
  display: inline-block;
  margin-bottom: 20px;
  text-align: center;
  line-height: 30px;
}

.SubscriptionErrorMessage {
  font-size: 12px;
  word-break: break-word;
  max-width: 550px;
  margin: 0 auto;
}

@media (max-width: 1250px) {
  .Header {
    display: flex;
    flex-direction: column;
    padding: 6px 10px;
  }
}


@media (max-width: 1400px) {
  .CalculatorContainer {
    display: flex;
    flex-direction: column;
  }

  .Content_Inputs {
    min-width: 500px;
  }

  .Content_Image, .Content_ImageSmaller {
    margin-bottom: 40px;
    order: -1;
  }

  .Content_Image {
    img {
      width: clamp(100px, 40%, 600px);
    }
  }

  .Content_ImageSmaller {
    img {
      width: clamp(100px, 30%, 600px);
    }
  }
}

@media (max-width: 1250px) {
  .Content_Inputs {
    width: 80%;
  }

  .Content_Image {
    img {
      width: clamp(100px, 60%, 600px);
    }
  }

  .Content_ImageSmaller {
    img {
      width: clamp(100px, 40%, 600px);
    }
  }
}

@media (max-width: 1000px) {
  .Content_Inputs {
    width: 100%;
    min-width: 400px;
  }

}

@media (max-width: 400px) {
  .Content_Inputs {
    min-width: 300px;
  }
}

