.Container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.CalculateModeCheckboxContainer {
  margin: 20px 0;
}
