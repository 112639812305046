.SectionContainer {
  margin-top: 30px;
  padding: 20px 16px;
  box-shadow: 0 1px 2px #ccc;
  background-color: white;
  cursor: pointer;
}

.MainRow {
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: space-between;
}

.InputsWrapper {
  display: flex;
  flex-direction: column;
  //align-items: center;
  padding: 10px 0;
}
