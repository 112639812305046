.FieldNumberTitle {
    font-size: 1em;
    letter-spacing: 1px;
    font-weight: bold;
}

.FieldTitleContainer {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
}

.IconsContainer {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .TrashIcon {
        margin-right: 20px;
        font-size: 0.8em;
        color: var(--color-negative-red);
        cursor: pointer;
    }

    .RemoveImageIcon {
        font-size: 0.8em;
        color: var(--color-negative-red);
        cursor: pointer;
    }

    .AddImageIcon {
        font-size: 1em;
        color: var(--color-info-blue);
        cursor: pointer;
    }
}
