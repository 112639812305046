.ProductPaymentContainer {
    display: block;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    font-size: 1.6rem;
    letter-spacing: 1px;
}

.RowsContainer {
    padding: 10px 20px;
    background-color: var(--color-gray-light);
    border-radius: 0 0 5px 5px;
}

.Header {
    padding: 10px 0;
    font-family: var(--header-font-default-bold);
    background-color: var(--color-negative-red);
    text-align: center;
    border-radius: 5px 5px 0 0;
}

.Header svg {
    font-size: 55px;
}

.OptionRow {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    line-height: 1.3em;
    text-align: center;
    color: black;
}

.ButtonContainer {
    align-self: center;
    width: 220px;
    margin-top: 15px;

    button {
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .OptionRow {
        flex-direction: column;
    }

    .ButtonContainer {
      margin: 10px auto;
    }

    .Header svg {
        font-size: 45px;
    }
}
