.ListContainer {
  margin: 20px 10px;

  h2 {
    margin: 0 0 20px;
    font-family: var(--header-font-secondary);
    font-size: var(--font-size-huge-rem);
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--theme-default-color-primary);
    //margin-bottom: 6px;
    //font-size: var(--font-size-extra-large-rem);
    //letter-spacing: 0.6px;
  }

  li {
    display: flex;
    align-items: flex-start;
    margin: 8px 0;
    font-size: 19px;
    font-weight: 600;
    color: #717171;
    line-height: 30px;

    div {
      display: flex;
      align-items: center;
      margin-right: 6px;
      margin-top: 8px;
    }

    svg {
      font-size: 16px;
      color: var(--theme-default-color-secondary);
    }

    a {
      color: var(--theme-default-color-secondary);
    }
  }
}

@media screen and (max-width: 1100px) {
  .ListContainer {

    h2 {
      font-size: var(--font-size-extra-large-rem);
    }
    li {
      font-size: var(--font-size-large-rem);
    }
  }
}
