.StandardViewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  //width: 50%;
  padding: 0 10px;
  margin-bottom: 40px;
  font-size: 1.2em;
}

.InputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.SingleInput{
  width: unset !important;
}

.ProductName {
  margin-right: 10px;
  font-size: 1.4em;
  font-family: 'Roboto-Bold', serif;
  white-space: nowrap;
  letter-spacing: 1px;
}

@media (max-width: 500px) {

  .InputContainer {
    flex-direction: column;
  }

}
