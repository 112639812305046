.Overlay {
  position: absolute;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.1); /* Black background with opacity */
  z-index: 3; /* Specify a stack order in case you're using a different order for other elements */
  // cursor: pointer; /* Add a pointer on hover */
  transition: max-height 0.5s ease-out;
}

.Dark {
  max-height: 0;
  background-color: rgba(0,0,0,0.7); /* Black background with opacity */
}

.OverlayOpen {
  max-height: 100vh;
  transition: max-height 0.5s ease-in;
}
