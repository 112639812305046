.Separator {
    width: 90%;
    height: 1px;
    background: #ccc
}

.blue {
    background: var(--theme-default-color-primary);
}

.pink {
    background: var(--theme-default-color-secondary);
}
