.Header {
  font-size: 20px;
  color: var(--header-color-default);
  cursor: pointer;
  margin-bottom: 20px;
}

.Paragraf {
  margin: 10px 0;
}

.ProductDetailsHeaderWrapper {
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  color: var(--header-color-default);
  cursor: pointer;
}
