.ListTitleRow {
  display: flex;
  align-items: center;
}

.AddCircleIcon {
  font-size: 1.6em;
  color: var(--color-info-blue);
  cursor: pointer;
  margin-left: 20px;
}

.RemoveCircleIcon {
  font-size: 1.6em;
  color: var(--color-negative-red);
  cursor: pointer;
  margin-left: 20px;
}
