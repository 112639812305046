.Logout {
  font-family: var(--paragrapf-font-default);
  font-weight: bold;
  font-size: var(--font-size-small-rem);
  letter-spacing: 0.6px;
  color: var(--theme-default-color-secondary);
  cursor: pointer;
}

.Logout:hover {
  color: white;
  background: #0d44bc;
}
