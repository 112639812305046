.SubPanelContainer {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  padding-top: 60px;
  border-radius: 3px;
  text-align: center;
}

.SubPanelContainer_Background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
}

.SubPanelContainer_Background svg {
  display: block;
  height: 100%;
  width: 100%;
}


@media screen and (max-width: 1100px) {
  .SubPanelContainer {
    flex-direction: column;
    align-items: center;
  }

  .SubPanelContainer_Background {
    height: 70%;
  }
}

@media screen and (max-width: 900px) {
  .SubPanelContainer {
    flex-direction: column;
    padding-top: 20px;
  }
}


@media screen and (max-width: 768px) {
  .SubPanelContainer_Background {
    display: none;
  }
}
