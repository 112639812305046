.FeatureContainer {
  width: 400px;

  h2 {
    font-family: var(--header-font-secondary);
    font-size: var(--font-size-normal-em);
    line-height: 22px;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }

  ul {
    margin: 20px;

    li {
      margin-bottom: 10px;
      line-height: 20px;
    }

    li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: var(--color-image-pink); /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }
}

@media only screen and (max-width:1550px) {
  .FeatureContainer {
    margin: 40px 0 20px;
  }
}

@media only screen and (max-width:500px) {
  .FeatureContainer {

    h2 {
      text-align: center;
    }

    width: 100%;
    text-align: justify;
  }
}

@media only screen and (max-width:330px) {
  .FeatureContainer {
    margin: 0;
    width: 100%;
  }
}
