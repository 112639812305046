.App {
  margin: 60px 0;
  height: 100vh;
  background-color: #f9fafa;
}

.LoginPageContainer {
  height: 100%;
  width: 100%;
  margin-bottom: 30px;
}
