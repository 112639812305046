.SidebarContainer {
    /*flex: 0 0 300px;*/
    /*position: relative;*/
    position: fixed;
    height: 100%;
    width: 300px;
    background: var(--theme-default-color-primary-decoration);
    transition: all 0.5s;
    box-shadow: 8px 3px 13px -4px rgba(0,0,0,0.5);
    z-index: 999;
}

.InnerSidebarContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 0 solid transparent;
    background: var(--theme-default-color-primary);
    overflow-x: hidden;
}


.Subscription {
    display: flex;
    margin-top: 20px;
    padding: 20px 15px;
    align-items: center;
    font-size: var(--font-size-large-rem);
    color: #e6dede;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.5s;
    cursor: pointer;
}

.Subscription:hover {
    color: white;
    background: var(--theme-default-color-light);
}


.SubscriptionAlert {
    display: flex;
    margin-top: 20px;
    padding: 20px 15px;
    align-items: center;
    font-size: var(--font-size-large-rem);
    cursor: pointer;
    background-color: var(--theme-default-color-secondary);
    transition: all 0.5s;
}

.SubscriptionAlert:hover {
    background-color: var(--theme-default-color-secondary-decoration);
}

.SubscriptionAlert_Active{
    background-color: var(--theme-default-color-secondary-decoration);
}


.SubscriptionAlert svg {
    color: white;
}

.SubscriptionAlert span {
    color: white;
}


.Hidden {
    margin-left: -300px;
    box-shadow: none;
}

@media only screen and (max-width: 768px) {
    .SidebarContainer {
        display: none;
    }
}
