.SingleFieldWrapperContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 0;
}

.ViewMode {
  width: 100%;
}

.Value {
  align-self: center;
  font-size: var(--font-size-large-rem);

  span {
    font-weight: bold;
  }
}

.IconContainer {
  font-size: var(--font-size-extra-large-rem);
  cursor: pointer;
  margin-left: 16px;
}

.TwoIconContainer {
  display: flex;
}

.EditIcon {
  color: var(--color-info-blue);
}

.EditIcon:hover {
  color: var(--color-info-blue-hover);
}

.CheckIcon {
  color: var(--color-positive);
}

.CheckIcon:hover {
  color: var(--color-positive-hover);
}

.TimesIcon {
  margin-left: 10px;
  color: var(--color-negative-red);
}

.TimesIcon:hover {
  color: var(--color-negative-red-hover);
}
