.Container {
  flex-direction: column;
}

.TitleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  width: 100%;
  font-family: var(--header-font-default-medium);
  font-size: 1.8em;
  cursor: pointer;
}

.Title {
  display: flex;
  align-items: center;
}

.FullPrice {
  font-weight: bold;
  margin-left: 20px;
}


.FieldsSection {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}

.PlusIcon {
  display: flex;
  margin-left: 20px;
  color: var(--color-info-blue);
  cursor: pointer;
}

.PlusIcon:hover {
  color: var(--color-info-blue-hover);
}

.ChevronIcon {
  display: flex;
  margin-left: 20px;
  cursor: pointer;
}
.Description {
  margin: 15px;
  padding: 5px;
  color: var(--paragraph-color-secondary);
  font-family: var(--paragrapf-font-default);
  font-size: var(--paragrapf-size-default);
  letter-spacing: var(--paragrapf-letter-spacing-default);
  line-height: var(--paragrapf-line-height-default);
}

@media (max-width: 400px) {
  .Description {
    text-align: justify;
  }

  .TitleRow {
    font-size: 1.6em;
  }

  .FullPrice {
    font-weight: bold;
    margin-left: 10px;
    white-space: nowrap;
  }
}
