.Ribbon {
  position: absolute;
  top: -1.9rem;
  left: 0;
  border-radius: 10px 10px 0 0;
  width: 100%;
}

.Ribbon_Text {
  background-color: var(--color-negative-red-hover);
  color: #ffffff;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 0.6rem 0;
  display: flex;
  border-radius: 10px 10px 0 0;
  justify-content: center;
  align-items: center;

  svg {
    fill: var(--color-negative-warning-hover);
    position: absolute;
    right: -4%;
    font-size: 6rem;
    transform: rotate(20deg);
  }
}



@media only screen and (max-width: 600px) {
  .Ribbon_Text {
    svg {
      right: -2%;
    }
  }
}








