.Paragraph {
    margin: 20px;
    font-family: var(--paragrapf-font-default);
    font-size: 17px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.9px;
    color: var(--paragraph-color-secondary);
}

.Paragraph a {
    color: var(--theme-default-color-secondary);
}

@media only screen and (max-width: 600px) {
    .Paragraph {
        margin: 6px;
        font-size: 14px;
    }
}
