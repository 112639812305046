.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 10px;
  font-size: 1.2em
}

.SectionContainer {
  display: flex;
  align-items: center;
}

.Input input {
  font-family: 'Roboto-Bold', serif;
  font-size: 1.1em;
  font-weight: bold;
}

.Input > div {
  width: 64px;
  text-align: center;
  white-space: nowrap;
}
.InputShort {
  width: 200px !important;
}

.InputShort input {
  width: 90px;
}

.IndexStandard {
  margin-right: 20px;
  font-size: 1.4em;
  font-weight: bold;
}

.IndexConfig {
  margin-bottom: 10px;
  font-size: 1.4em;
  font-weight: bold;
}

.RemoveText {
  margin-left: 30px;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--color-negative-red);
  cursor: pointer;
}

.RemoveText:hover {
 color: var(--color-negative-red-hover);
}

.EditText {
  margin-left: 30px;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--color-info-blue);
  cursor: pointer;
}

.EditText {
  color: var(--color-info-blue-hover);
}

.Price {
  font-size: 1.4em;
  font-weight: bold;
  white-space: nowrap;
}

.MarginBottom20 {
  padding: 0;
  margin: 0 0 20px 0;
}

@media (max-width: 1300px) {

  .IndexStandard {
    margin: 0;
  }
}

@media (max-width: 500px) {

  .IndexStandard {
    margin: 6px 0;
  }

  .RemoveText {
    margin: 6px 0 0 0;
    padding: 6px 15px;
  }

  .EditText {
    margin: 6px 0 0 0;
    padding: 6px 15px;
  }
}
