.ProductPaymentContainer {
  letter-spacing: 1px;
}

.ProductPaymentContainer button {
  padding: 10px 10px;
  font-size: 12px;
}

.InformationSection {
  padding: 13px 0;
  font-size: var(--font-size-normal-rem);
  font-family: var(--header-font-default-bold);
  background-color: var(--theme-default-color-secondary);
  color: white;
  text-align: center;
  margin-top: -1px;
}

.RedirectsSection {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1px;

  button {
    flex: 1 0 50%;
  }
}
