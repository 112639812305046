.ModalContainer {
  position: sticky;
  top: 0;
  right: 20px;
  width: 300px;
  background-color: #f9fafa;
  box-shadow: var(--card-box-shadow);
  z-index: 5;
}

.Row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 12px;
  font-size: 1.4em;
}

.Row span {
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 10px;
}

.SummaryRow {
  background: bisque;
  cursor: pointer;
}

.SummaryRow:hover {
  background: #fad4ac;
}

.SimpleRow {
  background: #fafafa;
}

.SimpleRow:nth-of-type(2n) {
  background: #fff7f1;
}

.SimpleRow:hover {
  background: #f1ecec;
}

.Icon {
  font-size: 1.2em;
  cursor: pointer;
}
