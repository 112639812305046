.MainContainer {
  width: 100%;
  margin-top: 30px;
  padding-bottom: 20px;
  box-shadow: 0 1px 2px #ccc;
  background-color: white;
}

.ContentSection {
  text-align: center;
}

.Container_Waves {
  display: block;
}
