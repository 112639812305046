.FiltersContainer {
  padding: 20px;
  box-shadow: var(--card-box-shadow);
}

.FiltersMainRow {
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: space-between;
}

.FiltersMainRowHeader {
  color: var(--header-color-default);
  cursor: pointer;
}

.FilterRowWrapper {
  display: block;
  text-align: center;
  padding: 10px 0;
  font-size: var(--font-size-normal-rem);

  p {
    font-size: var(--font-size-semi-large-rem);
    font-weight: bold;
    margin-bottom: 17px;
  }

  button {
    margin: 5px 15px 0 15px;
    max-width: 170px;
  }

}

@media only screen and (max-width: 880px) {
  .FilterRowWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin-bottom: 5px;
    }

    button {
      margin: 15px 0 5px 0;
      width: 100%;
      max-width: 140px;
    }
  }
}
