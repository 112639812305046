.AddProductContainer {
  padding-bottom: 16px;
  text-align: left;
  font-size: 1.4em;
  font-weight: bold;
  color: var(--color-info-blue);
}

.AddProductContainer:hover {
  color: var(--color-info-blue-hover);
}

.AddProductContainer span {
  cursor: pointer;
}
