.InputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

.Label {
    color: #3e5474;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 10px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    padding-bottom: 4px;
    margin-left: 1px;
    margin-bottom: 2px;
    text-align: left;
}

.ProgressComplete {
    position: absolute;
    top: 27px;
    right: 28px;
}

.ProgressComplete g path {
    fill: #00c462;
}

.NoMargin {
    margin-bottom: 0 !important;
}
