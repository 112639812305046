.HideIconContainer {
  position: absolute;
  left: 91%;
  top: 30px;
  color: white;
  border: 2px white solid;
  border-radius: 45px;
  background: var(--theme-default-color-light);
  font-size: 26px;
  padding: 12px;
  z-index: 20;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.HideIconContainer svg {
  vertical-align: top;
}

.HideIconContainer:hover {
  color: white;
  background: #0d44bc;
  transform: scale(1.10);
}

.HiddenState {
  left: 100%;
}
