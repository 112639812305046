.Burger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 20px;
  width: 20px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;

  span {
    width: 2rem;
    height: 2px;
    position: relative;
    border-radius: 10px;
    transition: all 0.3s linear;
    transform-origin: 1px;
    background: white;
  }

}

.Burger:focus {
  outline: none;
}

.BurgerOpen {

  :first-child {
    transform: rotate(45deg);
    height: 2.4px;
  }

  :nth-child(2) {
    opacity: 0;
    transform: translateX(20px);
  }

  :nth-child(3) {
    transform: rotate(-45deg);
    height: 2.4px;
  }

}

.BurgerClosed {

  :first-child {
    transform: rotate(0);
  }

  :nth-child(2) {
    opacity: 1;
    transform: translateX(0);
  }

  :nth-child(3) {
    transform: rotate(0);
  }

}
