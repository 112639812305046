.FormMainDetails {
    max-width: 900px;
    margin: 20px auto 40px;
    padding: 20px;
    box-shadow: 0 1px 2px #ccc;
    background-color: white;
    font-size: 16px;
}

.AssetsHeader {
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    color: var(--header-color-default);
    cursor: pointer;
}

.LoaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}
