.HowToStart_Container {
  width: 80%;
  margin: 0 auto;
  padding: 0 16px;

  a {
    color: var(--theme-default-color-primary);
  }

  &__Links p {
    font-weight: bold;
    font-size: var(--font-size-semi-large-rem);
    color: var(--theme-default-color-primary);
    margin-bottom: 10px;
  }
}

.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-default-color-primary);
  font-size: var(--font-size-huge-rem);
  margin-bottom: 10px;
}
