.NavBarContainer {
  max-height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--theme-default-color-primary);
  box-shadow: var(--strong-box-shadow);
  z-index: 6;
  overflow-y: auto;
  overflow-x: hidden;
}

.LogoContainer {
  display: flex;
  align-items: center;

  h1 {
    font-size: var(--font-size-huge-rem);
    color: white;
  }
}

.NavBarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  position: relative;
}

.NavigationContent {
  max-height: 0;
  background: inherit;
  color: white;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

.NavigationOpen {
  max-height: 100vh;
  transition: max-height 0.6s ease-in;
  overflow:  visible;
}


@media only screen and (min-width: 769px) {
  .NavBarContainer {
    display: none;
  }
}
