.StandardViewContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 10px;
  font-size: 1.2em
}

.SectionContainer {
  display: flex;
  align-items: center;
}

.InputContainer {
  margin-right: 20px;
  text-align: left;
  font-size: 1.1em;
  font-weight: bold;
}

.ProductName {
  margin-right: 10px;
  font-size: 1.4em;
  font-family: 'Roboto-Bold', serif;
  white-space: nowrap;
  letter-spacing: 1px;
}

@media (max-width: 1300px) {

  .StandardViewContainer {
    flex-direction: column;
    padding: 20px 10px;
    //margin: 0;
    border-bottom: 0.5px solid #cccc;
  }

  .SectionContainer {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .ProductName {
    margin: 10px 0;
  }

  .NameSection {
    flex-direction: column;
  }

}

@media (max-width: 500px) {
  .SectionContainer {
    flex-direction: column;
  }

  .SectionContainer {
    margin: 4px 0;
  }
}
