.SidebarSectionContainer {
    margin-bottom: 20px;
    font-size: 10px;
}


.SidebarSectionContainer:first-child {
    background-color: var(--theme-default-color-light);
    box-shadow: -10px 10px 20px -9px rgba(0,0,0,0.75);
}

@media only screen and (max-width: 768px) {
    .SidebarSectionContainer:first-child {
        box-shadow: none;
        background: var(--theme-default-color-primary);
    }
}
