.AddProductContainer {
  padding-bottom: 16px;
  text-align: left;
  font-size: 1.4em;
  font-weight: bold;
  color: var(--color-info-blue);
}

.AddSeparator {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 0.5px solid #cccc;
}

.AddProductContainer:hover {
  color: var(--color-info-blue-hover);
}

.AddProductContainer span {
  cursor: pointer;
}

@media (max-width: 1300px) {
  .AddSeparator {
    border-top: none;
  }

  .SectionContainer {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .ProductName {
    margin: 10px 0;
  }

  .NameSection {
    flex-direction: column;
  }
}
