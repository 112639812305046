.AboutCourse_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 16px;
  position: relative;
  background: rgba(255, 190, 201, 0.12);
  border-radius: 40px;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-default-color-primary);
  font-size: var(--font-size-huge-rem);
}


.Course_Card {
  margin: 55px auto 0 auto;
  max-width: 80%;
  position: relative;

  &__Background {
    width: 112%;
    height: 112%;
    position: absolute;
    top: -6%;
    left: -6%;
    background: rgba(255, 190, 201, 0.17);
  }
}


.Certificate_Image {
  position: relative;
  width: 50%;
  margin: 20px auto;

  &__PriceContainer {
    width: 80%;
    margin: 0 auto 10px auto;
    background: rgba(255, 190, 201, 0.32);
    border-radius: 20px;
    padding: 5px 20px;
    font-weight: bold;
  }


  &__CheckIcon {
    position: absolute;
    right: calc(50% - 90px);
    top: -7%;
    font-size: 55px;
  }

  &__Price {
    margin-bottom: 5px;
    font-family: var(--header-font-default-medium);
    font-size: var(--font-size-large-em);
    color: var(--color-info-blue);
  }
}


@media (max-width: 1250px) {
  .AboutCourse_Container {
    display: block;
  }

  .AboutCourse_Course {
    margin-top: 70px;
  }

  .Course_Card {
    max-width: 70%;
  }

  .Certificate_Image {
    position: relative;
    width: 40%;
    margin: 20px auto;
  }
}

@media (max-width: 1000px) {

  .Course_Card {
    max-width: 90%;
  }

  .Certificate_Image {
    width: 60%;

    &__PriceContainer {
      width: 100%;
    }


    &__CheckIcon {
      font-size: 50px;
    }
  }
}


@media (max-width: 500px) {
  .Certificate_Image {
    &__PriceContainer p {
      font-size: var(--font-size-normal-em);
    }
  }
}
