.Container {
  position: relative;
  margin: 20px 0;
}

.SearchBar {
  display: block;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(0, 29, 71, 0.04);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.4px;
  border: none;
  padding: 8px;
  padding-right: 32px;
  color: #001d47;
}

.SearchBar:focus,
.SearchBar:active {
  background-color: rgba(0, 123, 232, 0.16);
}

.SearchBar::placeholder {
  color: #7C8BA0;
}

.Searchbar__icon {
  position: absolute;
  top: 10px;
  right: 21px;
  font-size: 1.6em;
  color: var(--color-primary-pink);
  cursor: pointer;
}

.Pointer {
  cursor: pointer;
}
