.ModalMask {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 29, 71, 0.35);
}

.ModalWrapper {
  height: auto;
  padding: 30px 10px 10px 10px;
  width: 100%;
  max-width: 400px;
  //box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  align-self: center;
  font-size: var(--font-size-large-rem);
  font-family: var(--paragrapf-font-default);
  color: var(--font-color-default);
  font-weight: bold;
  overflow: hidden;
}

.ContentContainer {
  padding: 0 10px;

  p {
    line-height: 1.3em;
  }
}

.HeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px 15px 15px;
  font-size: var(--font-size-extra-large-rem);
  border-radius: 10px 10px 0 0;
  color: white;
  background-color: var(--color-subscription-premium);

  svg {
    color: var(--color-negative-red);
    cursor: pointer;
  }
}

.InputsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  button {
    width: 48%;
  }
}

.warning {
  background-color: var(--color-negative-red-hover);

  svg {
    color: white;
  }
}

.positive {
  background-color: var(--color-positive-hover);

  svg {
    color: white;
  }
}

.information {
  background-color: var(--color-subscription-basic);

  svg {
    color: white;
  }
}

.normal {
  background-color: white;
  color: black;
}
