.SectionContainer {
  margin: 0 auto;
  max-width: 1200px;

  a {
    font-weight: bold;
    color: var(--link-color-default);
  }

  a:hover {
    color: var(--link-color-default-hover);
  }
}

.SectionHeader {
  margin-top: 15px;
  padding: 15px 30px;
  font-size: var(--font-size-normal-em);
  background-color: var(--theme-default-color-secondary);
  border-radius: 5px 5px 0 0;
  text-align: center;

  p {
    font-family: var(--header-font-default-bold);
    color: white;
  }
}

.ProductDetailsSection {
  font-size: 16px;
}

.ProductAuthor {
  text-transform: uppercase;
  color: var(--color-graphit);
  font-size: 0.8em;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 3px;
}

.ProductLastUpdate {
  text-transform: uppercase;
  color: var(--color-graphit);
  font-size: 0.6em;
  font-weight: bold;
  letter-spacing: 1px;
}

.ProductLessonsRow {
  display: flex;
  margin: .4rem 0;
  position: relative;
  box-shadow: inset 0 -1px 0 0 #dcdacb;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.ProductLessonsItem {
  padding: 1rem 1.2rem;
  cursor: pointer;
  color: var(--color-graphit);
  font-weight: bold;
}

.ProductLessonsItem:hover {
  color: #181717;
  border-bottom: 1px solid #181717;
}

.ActiveLesson {
  color: #181717;
  border-bottom: 1px solid #181717;
}

.LessonContent {
  padding: 20px;
  max-width: 1200px;
  margin: 40px auto;
  border-radius: 10px;
  background-color: #ea9bad21;
  box-shadow: var(--shadow-around-light);
}

.LessonHeader {
  margin-bottom: 20px;
  color: var(--theme-default-color-primary);
  font-family: var(--header-font-secondary);
  font-size: var(--font-size-extra-huge-rem);
  letter-spacing: 4px;
  text-transform: uppercase;
}


@media (max-width: 1000px) {
  .SectionHeader {
    font-size: var(--font-size-tiny-rem);
  }
}


@media (max-width: 768px) {
  .SectionHeader {
    font-size: 0.8rem
  }

  .LessonHeader {
    font-size: var(--font-size-extra-large-rem);
  }
}

