.ItemStyles {
  align-items: center;
  margin: 2em 0 !important;
}

.CodeSpan {
  font-weight: bold;
  font-size: 1.2em;
  color: var(--color-info-blue);
  cursor: pointer;
}

.CodeSpan:hover {
  color: var(--color-info-blue-hover);
}
