.Container {
  margin: 20px;
  padding: 10px 25px 70px 25px;
  width: 100%;
  max-width: 550px;
  position: relative;
  background-color: var(--color-info-blue);
  border-radius: 10px;
  color: white;
  text-align: center;
  font-size: var(--font-size-normal-rem);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.Premium {
  background-color: var(--color-subscription-premium);
}

.TitlesRow {
  padding: 20px 0 35px 0;
  h2 {
    padding-bottom: 5px;
    margin: 0 auto;
    text-align: center;
    font-size: var(--font-size-extra-large-rem);
    font-weight: bold;
    letter-spacing: 4px;
    text-transform: uppercase;
  }
}

.PriceContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 20px;

  h2 {
    font-size: 5.6em;
    font-weight: bold;
  }

  span {
    font-size: 1.6em;
  }
}

.NewPriceContainer {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.NewPrice {
  font-size: 5.6em;
}

.NewPriceCurrency {
  font-size: 2.2em;
  font-weight: bold;
}

.NewPriceSubscriptionMonth {
  font-size: 1.4em;
  font-weight: bold;
}

.Content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: baseline;
}


.CrossedPrice {
  position: absolute;
  right: -30%;
  top: -30%;
  font-size: 2.5em;
  opacity: 0.8;
}

.CrossedPrice:after {
  content:"";
  position:absolute;
  top:50%;
  left:0;
  width:100%;
  height:4px;
  background: var(--color-negative-red);
  transform:rotate(-22deg);
}

.CrossedCurrency {
  font-size: 0.4em;
}

.PromotionText {
  color: #ffffff;
  font-weight: normal;

  span {
    font-weight: bold;
  }

  span:first-of-type {
    font-size: 1.5em;
  }
}

.SinglePoint {
  display: flex;
  margin-bottom: 10px;
  font-size: 1.1em;
  line-height: 1.3em;
  text-align: left;

  .Icon {
    margin-right: 10px;
    line-height: 1.5em;
    display: flex;
    align-items: center;
  }
}

.ButtonContainer {
  width: 80%;
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translate(-50%, 0);
}

.Button {
  width: 100%;
  height: 40px;
}

.Button_Option {
  min-width: 112px;
  height: 40px;
  border-radius: 10px;
}

.PaymentTypeRow {
  display: flex;
  gap: 7px;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
}


.Switcher {
  max-width: 400px;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 15px;
  font-weight: bold;
}

.SwitchNew {
  max-width: 400px;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 15px;
  font-weight: bold;
}

.SubOptionsWrapper {
  margin-top: 30px;
}
