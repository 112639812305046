/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

@font-face {
    font-family: 'Oregano';
    src: local('Oregano'), url(./fonts/Oregano/Oregano-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Bold';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(./fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: Hind-light;
    src: local(Hind-light), url(./fonts/Hind/Hind-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: Hind-Regular;
    src: local(Hind-Regular), url(./fonts/Hind/Hind-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Hind-Bold;
    src: local(Hind-Bold), url(./fonts/Hind/Hind-Bold.ttf);
    font-weight: 700;
}

:root {
    --media-query-mobiles: 600px;
    --media-query-tablets: 768px;
    --media-query-desktop-star: 992px;
    --media-query-desktop-bigger: 1200px;

    --font-size-tiny-em: 1em;
    --font-size-small-em: 1.2em;
    --font-size-normal-em: 1.4em;
    --font-size-semi-large-em: 1.8rem;
    --font-size-large-em: 2em;
    --font-size-extra-large-em: 2.2em;
    --font-size-huge-em: 2.6em;

    --font-size-tiny-rem: 1rem;
    --font-size-small-rem: 1.2rem;
    --font-size-normal-rem: 1.4rem;
    --font-size-large-rem: 1.6rem;
    --font-size-semi-large-rem: 1.8rem;
    --font-size-extra-large-rem: 2.2rem;
    --font-size-huge-rem: 2.8rem;
    --font-size-extra-huge-rem: 3.4rem;


    /********* Header START **********/

    --header-size-default: 2.2em;

    --header-font-default-bold: Roboto-Bold, serif;
    --header-font-default-medium: Roboto-Medium, serif;
    --header-font-secondary: Oregano, serif;
    --header-font-thirdary: Hind-Regular, serif;

    --header-letter-spacing-default: 0.5px;

    --header-line-height-default: 2.4em;

    /********* Header End **********/


    /********* Paragraph START **********/

    --paragrapf-size-default: 1.6rem;

    --paragrapf-font-default: Hind-Regular, serif;
    --paragrapf-font-secondary: OpenSans, serif;

    --paragrapf-letter-spacing-default: 0.2px;

    --paragrapf-line-height-default: 1.6em;


    /********* Paragraph END **********/


    /********* COLORS START **********/

    --theme-default-color-primary: #1156bb;
    --theme-default-color-primary-decoration: #0c3e86;
    --theme-default-color-light: #2265c4;
    --theme-default-color-secondary: #ea9bad;
    --theme-default-color-secondary-decoration: #ffbec9;



    --font-color-default: #353434;

    --header-color-default: #282829;
    --paragraph-color-default: var(--font-color-default);
    --paragraph-color-secondary: #706f6f;

    --link-color-default: #224488;
    --link-color-default-hover: #4d81e2;

    --color-info-blue: #255ff1;
    --color-info-blue-hover: #729aff;

    /*--color-negative-red: #a91818;*/
    --color-negative-red: rgba(212, 58, 58, 0.9);
    --color-negative-red-hover: #ee4040;

    --color-negative-warning: #9d9849;
    --color-negative-warning-hover: #efc839;

    --color-positive: #0d861f;
    --color-positive-hover: #25ba38;

    /*--color-subscription-premium: #fda835;*/
    --color-subscription-premium: #fc841b;
    --color-subscription-basic: #2679cd;
    --color-subscription-free: #178013;

    --background-color-premium-rgba: rgba(253, 168, 53, 0.8);
    --background-color-basic-rgba: rgba(38, 121, 205, 0.8);
    --background-color-free-rgba: rgba(23, 128, 19, 0.8);

    --color-gray-light: #e0e0e0;
    --color-gray-dark: #c6c6c6;

    --color-image-pink: #ED1E79;
    --color-image-green: #39B54A;
    --color-image-blue: #00ADEE;

    --color-disabled: #cec0c0ed;

    /********* COLORS END **********/


    /********* DECORATIONS START *********/

    --card-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    --strong-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.7);
    --shadow-around: 5px 5px 15px -4px rgba(0,0,0,0.5);
    --shadow-around-light: 0 1px 2px #ccc;

    /********* DECORATIONS END *********/

    --button-color-red-background-hover: #ffe6e6;
    --button-color-red-border-hover: #d82441;
    --error-message-text: red;

    --info-notification-bg: #d8ebfc;
    --info-notification-text-color: #0060ba;

    --reject-reason-bg-color: #ffe8e6;
    --reject-reason-text-color: #db2828;
    --reject-reason-border-color: #FE8BAE;




    /********* KAROL END **********/
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

html {
    font-size: 10px;
}

body {
    margin: 0;
    line-height: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

ol, ul {
    list-style: none;
}

a {
    color: inherit;
}

a:hover {
    color: inherit;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1, h2, h3, h4, h5 {
    font-family: var(--header-font-default-bold);
    font-weight: 700;
    padding: 0;
}

p, span, li {
    margin: 0;
    padding: 0;
    font-size: var(--paragrapf-font-default);
}

strong {
    font-weight: bold;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.default-h2 {
    margin-bottom: 20px;
    color: var(--header-color-default);
    font-family: var(--header-font-default-bold);
    font-size: var(--header-size-default);
}

.default-paragraph {
    margin-bottom: 20px;
    color: var(--paragraph-color-secondary);
    font-family: var(--paragrapf-font-default);
    font-size: var(--paragrapf-size-default);
    letter-spacing: var(--paragrapf-letter-spacing-default);
    line-height: var(--paragrapf-line-height-default);
}

.show {
    display: block;
}

.hide {
    display: none !important;
}

.free-background-color {
    background-color: var(--color-subscription-free);
}

.basic-background-color {
    background-color: var(--color-subscription-basic);
}

.premium-background-color {
    background-color: var(--color-subscription-premium);
}

.free-color {
    color: var(--color-subscription-free);
}

.basic-color {
    color: var(--color-subscription-basic);
}

.premium-color {
    color: var(--color-subscription-premium);
}

.nav-no-link-menu-item {
    display: block;
    width: 100%;
    padding: 16px 20px;
    color: #e6dede;
    font-family: var(--header-font-default-medium);
    font-size: var(--font-size-normal-rem);
    letter-spacing: 1px;
}

.nav-menu-header-item {
    display: flex;
    padding: 20px 15px;
    align-items: center;
    font-size: var(--font-size-large-rem);
    color: #e6dede;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.nav-menu-header-item_clickable {
    display: flex;
    padding: 20px 15px;
    align-items: center;
    font-size: var(--font-size-large-rem);
    color: #e6dede;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.5s;
    cursor: pointer;
}

.nav-menu-header-item_clickable:hover {
    color: white;
    background: rgba(255, 255, 255, 0.2);
}

.nav-menu-header-item_clickable_active {
    background: rgba(255, 255, 255, 0.2);
}

.nav-menu-item {
    display: block;
    width: 100%;
    padding: 16px 20px;
    color: #e6dede;
    font-family: var(--header-font-default-medium);
    font-size: var(--font-size-normal-rem);
    letter-spacing: 1px;
    transition: all 0.5s;
    cursor: pointer;
}

.nav-menu-item:hover {
    color: white;
    background: rgba(255, 255, 255, 0.2);
}

.nav-menu-item_active {
    background: rgba(255, 255, 255, 0.2);
}

.ui.active.visible.selection.dropdown .visible.menu.transition .item {

}

.ui.fluid.search.selection.dropdown div.item {
    font-size: var(--font-size-normal-rem);
}

.ui.selection.dropdown:hover {
    border: 1px solid var(--theme-default-color-primary-decoration);
}

.ui.input input:hover {
    border: 1px solid var(--theme-default-color-primary-decoration);
}


.ui.dropdown .menu > .item {
    padding: 10px 0;
    font-size: 1em;
}

.ui.labeled.input {
    width: 100%;
}

.ui.labeled.input .ui.label.label {
    color: white;
    background-color: var(--theme-default-color-primary);
}

.vp-controls-wrapper .vp-sidedock {
    display: none !important;
}

.negative {
    color: var(--color-negative-red);
}

.positive {
    color: var(--color-positive);
}

div.ui.active.dimmer.fullScreenOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.sidebar-hidden #main-view-wrapper {
    margin-left: 0;
}
