.Container {
  position: fixed;
  top: -55px;
  width: 100vw;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.2px;
  font-size: var(--font-size-large-rem);
  line-height: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.2px;
  z-index: 20;
  transition: top 1.5s ease-in-out;
  visibility: visible;
  opacity: 0.9;
}

.Container.Info {
  background-color: var(--info-notification-bg);
  color: var(--info-notification-text-color);
  box-shadow: 0 0 0 1px #0060BA inset, 0 0 0 0 transparent;
}

.Container.Error {
  background-color: var(--reject-reason-bg-color);
  color: var(--reject-reason-text-color);
  box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;
}

.Container.Visible {
  top: 0;
  visibility: visible;
}

@media only screen and (max-width: 700px) {
  .Container {
    padding: 16px;
    line-height: 19px;
  }
}
