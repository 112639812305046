.Button {
  padding: 4px 12px;
  font-size: var(--font-size-large-rem);
  font-weight: bold;
  color: var(--color-info-blue);
  background-color: transparent;
  border: 2px solid var(--color-info-blue);
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.Button:hover {
  transform: scale(1.15);
}

.premium {
  color: var(--color-subscription-premium);
  border: 2px solid var(--color-subscription-premium);
}

.premiumActive {
  color: white;
  background-color: var(--color-subscription-premium);
  transform: scale(1.15);
  box-shadow: 1px 4px 12px -4px rgba(0, 0, 0, 0.49);
}

.basic {
  color: var(--color-subscription-basic);
  border: 2px solid var(--color-subscription-basic);
}

.basicActive {
  color: white;
  background-color: var(--color-subscription-basic);
  transform: scale(1.15);
  box-shadow: 1px 4px 12px -4px rgba(0, 0, 0, 0.49);
}

.free {
  color: var(--color-subscription-free);
  border: 2px solid var(--color-subscription-free);
}

.freeActive {
  color: white;
  background-color: var(--color-subscription-free);
  transform: scale(1.15);
  box-shadow: 1px 4px 12px -4px rgba(0, 0, 0, 0.49);
}

@media only screen and (max-width: 880px) {
  .Button:hover {
    transform: none;
  }
}
