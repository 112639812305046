.Container {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.Container input {
  padding: 10px 20px;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: rgba(0,0,0,.87);
  transition: box-shadow .1s ease,border-color .1s ease;
  box-shadow: none;
}


.Label {
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: white;
  background-color: var(--theme-default-color-primary);
}

.Error {
  color: var(--color-negative-red);
  background-color: #fae5e5;
  border: 1px solid var(--color-negative-red);
}

.ErrorContainer {
  position: absolute;
  top: -39px;
  left: 0;
  padding: 10px 15px;
  font-size: 14px;
  color: var(--color-negative-red);
  background-color: #fae5e5;
  border: 1px solid var(--color-negative-red);
  white-space: nowrap;
}
