.MainViewContainer {
    position: relative;
    margin-left: 300px;
    width: 100%;
    min-width: 500px;
    height: 100%;
    padding: 10px 40px 40px 40px;
    transition: all 0.5s;
    overflow: auto;
}

@media only screen and (max-width: 768px) {
    .MainViewContainer {
        min-width: 300px;
        margin: 0 0 60px 0;
        padding: 0 60px 60px 60px;
    }
}

@media only screen and (max-width: 600px) {
    .MainViewContainer {
        padding: 0;
    }
}

@media only screen and (max-width: 400px) {
    .MainViewContainer {
        min-width: 100%;
        padding: 0;
    }
}
