.ProductCardContainer {
  display: block;
  width: 100%;
  --grid-column-gap: 30px;
  --grid-row-gap: 35px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
  box-shadow: var(--card-box-shadow);
  font-size: 10px;
  transition-duration: .25s;
  cursor: pointer;
  position: relative;
  background-color: white;
}

.ProductCard_Invisible {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(115, 115, 115, 0.38);
}

.ProductCardContainer:hover {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15);
  -moz-transform: translate(-2px, -2px);
  -ms-transform: translate(-2px, -2px);
  -o-transform: translate(-2px, -2px);
  -webkit-transform: translate(-2px, -2px);
  transform: translate(-2px, -2px);
}
.ContentPositioning {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ImageSection {
  position: relative;
  overflow: hidden;
  /*  max-height: 300px;*/

  img {
    max-width:100%;
  }
}

.HeaderSection {
  margin-top: 20px;
  padding: 0 30px;
  margin-bottom: 20px;
}

.LabelTagContainer {
  position: absolute;
  top: 10px;
  left: -17px;
  z-index: 4;
}

.CourseDurationLabel {
  padding: 6px 10px 2px 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(239, 239, 239, 0.87);
  border-radius: 5px 0 0 0;
  text-align: center;
  z-index: 4;
}

.CourseDurationLabel_Text {
  margin-bottom: 25px;
  font-family: var(--header-font-thirdary);
  font-size: var(--font-size-semi-large-em);
  font-weight: 700;
  color: #151515;
}
