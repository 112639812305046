.ImageContainer {
    display: flex;
    flex-direction: column;
    margin: 60px 0;
    align-items: center;
    width: 100%;
}

.ImageContainer img {
    box-shadow: var(--shadow-around);
    width: clamp(260px, 90%, 1000px);
}

.Caption {
    margin-top: 4px;
    font-size: var(--font-size-small-rem);
    font-style: italic;
}
