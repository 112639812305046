.TextSwitchContainer {
  padding: 8px 4px;
  border-radius: 20px;
  background: #353b48;
}

.SwitchBtnWrapper {
  display: flex;
  justify-content: center;
  padding: 2px;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.1s linear;
}

.SwitchBtnWrapper>p:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.SwitchBtnWrapper>p:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.NormalParam {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cec0c0ed;
  padding: 8px 20px;
  font-size: 0.9em;
  transition: color 0.1s linear;
}

.NormalParam:hover {
  background: lighten(#cec0c0ed, 5%)
}

.ActiveParam {
  display: flex;
  justify-content: center;
  align-items: center;
  background: orange;
  padding: 8px 20px;
  font-size: 1.2em;
  transition: color 0.1s linear;
}
