.MessageContainer {
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
  background-color: #fcfff5;
  color: #9f3a38;
  font-size: 1em;
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  padding: 1em 1.5em;
  line-height: 1.4285em;
}
