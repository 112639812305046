.ProductLessonContainer {
    margin-bottom: 30px;
}

.TitleContainer {
    display: flex;
    align-items: center;
    font-size: var(--font-size-extra-large-rem);
}

.Header {
    font-size: var(--font-size-extra-large-rem);
    color: var(--header-color-default);
    cursor: pointer;
}

// Icons

.IconsContainer {

    display: flex;
    align-items: center;
    margin-left: 10px;

    .TrashIcon {
        margin-right: 20px;
        font-size: 0.8em;
        color: var(--color-negative-red);
        cursor: pointer;
    }
}






.LessonAllFieldSection {
    margin: 20px 0;
}

.LessonFieldSettings {
    display: flex;
    justify-content: space-between;
}

.LoaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}
