.CalculatorCardContainer {
  display: block;
  width: 100%;
  --grid-column-gap: 30px;
  --grid-row-gap: 35px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
  box-shadow: var(--card-box-shadow);
  font-size: 10px;
  transition-duration: .25s;
  cursor: pointer;
}

.CalculatorCardContainer:hover {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15);
  -moz-transform: translate(-2px, -2px);
  -ms-transform: translate(-2px, -2px);
  -o-transform: translate(-2px, -2px);
  -webkit-transform: translate(-2px, -2px);
  transform: translate(-2px, -2px);
}

.ContentPositioning {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ImageSection {
  position: relative;
  overflow: hidden;
  /*max-height: 300px;*/

  img {
    max-width: 100%;
  }
}

.HeaderSection {
  margin-top: 20px;
  padding: 0 30px;
  margin-bottom: 20px;
}

.LabelTagContainer {
  position: absolute;
  top: 10px;
  left: -17px;
  z-index: 4;
}
