.CMSContainer {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

@media only screen and (min-width: 769px) {
    .CMSContainer {
        margin-top: -60px;
    }
}

@media only screen and (max-width: 600px) {
    .CMSContainer {
        flex-direction: column;
    }
}
