.DefaultParagraph {
  margin-bottom: 20px;
  color: var(--paragraph-color-secondary);
  font-family: var(--paragrapf-font-default);
  font-size: var(--paragrapf-size-default);
  letter-spacing: var(--paragrapf-letter-spacing-default);
  line-height: var(--paragrapf-line-height-default);

  a {
    color: var(--color-info-blue);
  }

  a:hover {
    color: var(--color-info-blue-hover);
  }
}



.big {
  font-size: 1.8rem;
}

.bigger {
  font-size: 1.7rem;
}

.small {
  font-size: 1.4rem;
}


.smaller {
  font-size: 1.5rem;
}
