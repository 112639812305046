.Container {
  margin: 40px 0;
  padding: 20px 0;
  box-shadow: var(--card-box-shadow);
}

.CalculatorTitleContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 20px 20px;
}

.CalculatorContainerActions {
  display: flex;
  align-items: center;
}

.CalculatorTitle {
  white-space: nowrap;
  font-size: var(--font-size-extra-large-rem);
  cursor: pointer;
}

.SearchContainer::-webkit-scrollbar {
  display: none;
}

.SearchContainer {
  max-height: 300px;
  overflow: auto;
  font-size: var(--font-size-normal-rem);
}

.PricingNameSection {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.StorePricingText {
  margin: 0 20px;
  color: var(--color-positive);
  font-size: var(--font-size-normal-rem);
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
}

.StorePricingTextWarning {
  margin: 0 20px;
  color: var(--color-negative-warning);
  font-size: var(--font-size-normal-rem);
  font-weight: bold;
  white-space: nowrap;
}

.StorePricingText:hover {
  color: var(--color-positive-hover);
}

.AddNewPricingText {
  margin-left: auto;
  color: var(--color-info-blue);
  font-size: var(--font-size-normal-rem);
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
}

.AddNewPricingText:hover {
  color: var(--color-info-blue-hover);
}

.LimitForNewPricingText {
  margin-left: auto;
  color: var(--color-negative-warning);
  font-size: var(--font-size-normal-rem);
  white-space: nowrap;
}

.LimitForNewPricingText {
  color: var(--color-negative-warning-hover);
}

.EditIcon {
  margin-left: 10px;
  cursor: pointer;
}

.EditIcon svg {
  height: 20px;
  width: 20px;
  color: var(--color-info-blue);
}

.InputProductName {
  //width: 40%;
  min-width: 150px;
}

@media (max-width: 1400px) {
  .CalculatorTitleContainer {
    flex-direction: column;
    margin: 0;
  }

  .CalculatorContainerActions {
    display: flex;
    width: 300px;
    margin: 16px auto;
    padding: 0 20px;
  }

  .StorePricingText {
    margin: 0 16px 0 0;
  }
}

@media (max-width: 1000px) {
  .CalculatorContainerActions {
    flex-direction: column;
  }

  .AddNewPricingText {
    margin: 20px 0 0 0;
  }

  .LimitForNewPricingText {
    margin: 20px 0 0 0;
  }
}

@media (max-width: 360px) {
  .CalculatorTitle {
    font-size: var(--font-size-small-rem);
  }
}
