.CalculatorsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  /*margin: 0 auto;*/
}

@media screen and (max-width: 1420px) {
  .CalculatorsContainer {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}

@media screen and (max-width: 980px) {
  .CalculatorsContainer {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}
