.Container {
  padding: 20px;
}

.Label {
  cursor: pointer;
}

.Label i {
  margin-left: 20px !important;
}

.IconContainer {
  margin: 0 5px;
}

@media (max-width: 380px) {
  .Label {
    font-size: var(--font-size-small-rem) !important;
  }

}
