.ModalContainer {
  margin: 0 40px;
  //margin: 0 auto;
  width: 540px;
  padding: 60px;
  background-color: #fff;
  border: 1px solid #e6e9ec;
  border-radius: 3px;
  text-align: center;
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .09);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .09);
}

@media only screen and (max-width: 600px) {
  .ModalContainer {
    padding: 20px;
    width: 90%;
    max-width: 540px
  }
}

@media only screen and (max-width: 600px) {
  .ModalContainer {
    padding: 10px;
    width: 100%;
    max-width: 540px
  }
}
