.FooterContainer {
    display: flex;
    justify-content: center;
    padding: 6px 0;
    width: 100%;
    text-align: center;
    line-height: 20px;
    background-color: initial;
    color: var(--paragraph-color-default);
    font-size: 15px;
    font-family: var(--header-font-default-medium);
}
