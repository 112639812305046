.LoginPageContainer {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    font-size: var(--font-size-large-rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.InfoSectionsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Link {
    cursor: pointer;
    color: var(--link-color-default);
    font-size: var(--font-size-large-rem);
}

.Link:hover {
    color: var(--link-color-default-hover);
}

.ImageContainer {
    display: flex;
    justify-content: center;
    margin: 50px 0 16px 0;
    padding: 0 10px;
    text-align: center;

    img {
        width: clamp(100px, 90%, 600px);
    }
}

.FooterContainer {
    display: block;
    padding: 20px 0;
}

@media only screen and (max-width:1550px) {
    .InfoSectionsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
