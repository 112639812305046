.Header {
  margin-bottom: 20px;
  line-height: 30px;
  color: var(--header-color-default);
  font-size: var(--header-size-default);
  font-family: var(--header-font-default-bold);
  letter-spacing: var(--header-letter-spacing-default);
  font-weight: bold;
}

.HeaderLink {
  margin-bottom: 20px;
  color: var(--link-color-default);
  font-size: var(--header-size-default);
  font-family: var(--header-font-default-bold);
  letter-spacing: var(--header-letter-spacing-default);
  font-weight: bold;
  cursor: pointer;
}

.HeaderLink:hover {
  color: var(--link-color-default-hover);
}
