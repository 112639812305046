.Container {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding-bottom: 10px;
  font-size: 1.2em
}

.InputContainer {
  position: relative;
  text-align: left;
  font-size: 1.1em;
  font-weight: bold;
}

.Paragraph {
  position: absolute;
  top: -20px;
}

.Input {
  margin-top: 6px;
  padding-right: 20px;
}

.Input input {
  font-family: 'Roboto-Bold', serif;
  font-size: 1.1em;
  font-weight: bold;
}

.Price {
  font-size: 1.4em;
  font-weight: bold;
  white-space: nowrap;
}

.MarginRight {
  margin-right: 20px;
}

@media (max-width: 1100px) {
  .Container {
    flex-direction: column;
  }

  .InputContainer {
    margin-top: 30px;
  }

  .Price {
    margin-top: 10px;
  }
}
