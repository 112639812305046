.Button {
  padding: 10px 30px;
  font-size: var(--font-size-normal-rem);
  font-weight: bold;
  color: #fff;
  background-color: var(--color-info-blue);
  border: 2px solid var(--color-info-blue);
  cursor: pointer;
  transition: 0.25s ease;
  white-space: nowrap;
}

.Button:hover {
  color: var(--color-info-blue);
  background-color: white;
}

.blue-invert {
  color: var(--color-info-blue);
  border: 2px solid var(--color-info-blue);
  background-color: white;
}

.blue-invert:hover {
  color: white;
  border: 2px solid white;
  background-color: var(--color-info-blue);
}

.blue-invert.active {
  color: white;
  border: 2px solid white;
  background-color: var(--color-info-blue);
}

.blue {
  color: white;
  border: 2px solid white;
  background-color: var(--color-info-blue);
}

.blue:hover {
  color: var(--color-info-blue);
  border: 2px solid var(--color-info-blue);
  background-color: white;
}

.blue.active {
  color: var(--color-info-blue);
  border: 2px solid var(--color-info-blue);
  background-color: white;
}


.premium-invert {
  color: var(--color-subscription-premium);
  border: 2px solid var(--color-subscription-premium);
  background-color: white;
}

.premium-invert:hover {
  color: white;
  border: 2px solid white;
  background-color: var(--color-subscription-premium);
}

.premium-invert.active {
  color: white;
  border: 2px solid white;
  background-color: var(--color-subscription-premium);
}

.premium {
  color: white;
  border: 2px solid white;
  background-color: var(--color-subscription-premium);
}

.premium:hover {
  color: var(--color-subscription-premium);
  border: 2px solid var(--color-subscription-premium);
  background-color: white;
}

.premium.active {
  color: var(--color-subscription-premium);
  border: 2px solid var(--color-subscription-premium);
  background-color: white;
}

.warning {
  color: white;
  border: 2px solid var(--color-negative-warning);
  background-color: var(--color-negative-warning);
}

.warning:hover {
  color: var(--color-negative-warning);
  border: 2px solid var(--color-negative-warning);
  background-color: white;
}

.warning.active {
  color: var(--color-negative-warning);
  border: 2px solid var(--color-negative-warning);
  background-color: white;
}

.green {
  background-color: var(--color-positive);
  border: 2px solid var(--color-positive);
}

.green:hover {
  color: var(--color-positive);
  background-color: white;
}

.red {
  background-color: var(--color-negative-red);
  border: 2px solid var(--color-negative-red);
}

.red:hover {
  color: var(--color-negative-red);
  background-color: transparent;
}

.red-transparent {
  color: var(--color-negative-red-hover);
  background-color: white;
  border: 2px solid var(--color-negative-red-hover);
}

.red-transparent:hover {
  color: white;
  background-color: var(--color-negative-red-hover);
}

.red-transparent.active {
  color: white;
  background-color: var(--color-negative-red-hover);
}

.Disabled {
  color: black;
  background-color: var(--color-disabled);
  border: 2px solid var(--color-disabled);
  cursor: not-allowed;
}

.Disabled:hover {
  color: black;
  background-color: var(--color-disabled);
  border: 2px solid var(--color-disabled);
  cursor: not-allowed;
}

.Disabled.active {
  color: black;
  background-color: var(--color-disabled);
  border: 2px solid var(--color-disabled);
  cursor: not-allowed;
}
