.MainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ErrorContent {
  max-width: 900px;
  width: 100%;
  padding: 100px 60px 200px 60px;
  position: relative;
  text-align: center;
  background: #fff;
  box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.ContentWave {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.ContentWave svg {
  border-radius: 0 0 25px 25px;
}

.ContentTextSection {
  z-index: 30;
}

.ContentTitle {
  font-family: var(--header-font-default-medium);
  font-size: 165px;
  font-weight: 700;
  color: #262626;
  text-transform: uppercase;
}

.ContentTitle span {
  color: var(--theme-default-color-primary);
}


.ContentSubtitle {
  font-family: var(--header-font-default-medium);
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  color: #151515;
  margin-bottom: 25px;
}


@media (max-width: 768px) {
  .ErrorContent {
    padding: 130px 60px;
  }

  .ContentTitle {
    font-size: 120px;
  }

  .ContentSubtitle {
    font-size: 15px;
  }

  .ContentWave {
    display: none;
  }
}


@media (max-width: 400px) {
  .ErrorContent {
    padding: 100px 60px;
  }

  .ContentTitle {
    font-size: 100px;
  }

  .ContentSubtitle {
    font-size: 13px;
  }
}
