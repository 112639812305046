.ProductsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    /*margin: 0 auto;*/
}

.ContainerAdminButtons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.FiltersContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 20px 0;
}

.SingleProduct {
    margin: 0 auto;
}

.PaginationContainer {
    margin: 16px auto;
    text-align: center;
}

@media screen and (max-width: 1260px) {
    .ProductsContainer {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
}

@media screen and (max-width: 980px) {
    .ProductsContainer {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }
}


@media screen and (max-width: 400px) {
    .ContainerAdminButtons {
        display: block;
        text-align: center;
        margin: 0 auto;
    }

    .ContainerAdminButtons button {
        margin-bottom: 20px;
    }
}
