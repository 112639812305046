.SectionContainer {
  margin-top: 30px;
  padding: 20px 16px;
  box-shadow: 0 1px 2px #ccc;
  background-color: white;
  cursor: pointer;
}

.MainRow {
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: space-between;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  //align-items: center;
  //margin-top: 20px;
  padding: 10px 0;
}

.AlertNotificationRow {
  font-size: var(--font-size-normal-rem);
  font-weight: bold;
  color: var(--color-negative-red);
}

.SingleRow {
  margin-top: 12px;
  font-size: var(--font-size-large-rem);

  span {
    font-weight: bold;
  }
}

.ButtonContainer {
  display: flex;
  margin-top: 20px;
  width: 50%;
}

.AdditionalBtn {
  margin-top: 20px;
}

.MainSubscriptionType {
  font-size: var(--font-size-extra-large-rem);
}

.SecondarySubscriptionType {
  font-size: var(--font-size-large-rem);
}

.ActivePackagesTitleContainer {
  margin-bottom: 20px;
}
