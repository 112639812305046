.PremiumType {
  .ui.tag.label {
    background-color: var(--background-color-premium-rgba) !important;
    color: white;
    letter-spacing: 2px;
  }
}

.BasicType {
  .ui.tag.label {
    background-color: var(--background-color-basic-rgba);
    color: white;
    letter-spacing: 2px;
  }
}

.FreeType {
  .ui.tag.label {
    background-color: var(--background-color-free-rgba);
    color: white;
    letter-spacing: 2px;
  }
}
