.SwitchLessonRowContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px;
  color: white;
  background: var(--theme-default-color-secondary);
  font-size: var(--font-size-extra-large-rem);
  border-radius: 0 0 5px 5px ;
}

.SearchContainer {
  width: 80%;
  font-size: var(--font-size-normal-rem);
}

.ChevronIconContainer {
  cursor: pointer;
}
