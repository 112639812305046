.AboutQuestions_Container {
  margin-bottom: 20px;
  padding: 0 16px;

  a {
    color: var(--theme-default-color-primary);
  }

  a:hover {
    color: var(--link-color-default-hover);
  }

  svg {
    vertical-align: middle;
    font-size: 25px;
  }
}

.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-default-color-primary);
  font-size: var(--font-size-huge-rem);
}
