.FormContainer {
    display: flex;
    flex-direction: column;
    background: white;
}

.AdditionalInfoContainer {
    margin: 20px 0 10px 0;

    p {
        margin-bottom: 16px;
    }
}

.Link {
    cursor: pointer;
    color: var(--link-color-default);
    font-weight: bold;
    font-size: var(--font-size-semi-large-rem);
}

.Link:hover {
    color: var(--link-color-default-hover);
}

.FormContainerButton {
    color: white;
}
