.CustomHeaderContainer {
  display: flex;
  font-size: var(--font-size-large-rem);
  color: #e6dede;
  letter-spacing: 1.1px;

  .IconContainer {
    margin-right: 16px;
  }
}

.TypicalH2 {
  font-size: var(--font-size-extra-large-rem);
  color: var(--header-color-default);
}
